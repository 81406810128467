var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-height align-start",
      class: _vm.$config("app.layoutColors.containerBackground")
    },
    [
      [
        _c(
          "v-main",
          [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { staticClass: "justify-center" },
                  [
                    _c("v-img", {
                      class: _vm.$vuetify.breakpoint.mobile ? "my-4" : "my-12",
                      attrs: {
                        src:
                          "https://pacific-screening-static.s3.amazonaws.com/images/psi-mark-alt.png",
                        "max-height": "84",
                        "max-width": "84"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-col",
                      {
                        class: _vm.$vuetify.breakpoint.mdAndUp ? "px-12" : "",
                        attrs: { cols: "12", lg: "4", md: "6" }
                      },
                      [_c("router-view")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }