<template>
    <div
        class="fill-height align-start"
        :class="$config('app.layoutColors.containerBackground')"
    >
        <template>
            <v-main>
                <v-container fluid>
                    <v-row class="justify-center">
                        <v-img
                            src="https://pacific-screening-static.s3.amazonaws.com/images/psi-mark-alt.png"
                            max-height="84"
                            max-width="84"
                            :class="
                                $vuetify.breakpoint.mobile ? 'my-4' : 'my-12'
                            "
                        />
                    </v-row>
                    <v-row justify="center">
                        <v-col
                            cols="12"
                            lg="4"
                            md="6"
                            :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ''"
                        >
                            <router-view> </router-view>
                        </v-col>
                    </v-row>
                </v-container>
            </v-main>
        </template>
    </div>
</template>

<script>
export default {
    beforeCreate() {},
    created() {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
</style>